const PUBLIC_ROUTES = {
  login: "/login",
  signIn: "/sign-in",
  forgotPassword: "/forgot-password",
} as const;

const PRIVATE_ROUTES = {
  home: "/",
} as const;

export const ROUTES = {
  ...PUBLIC_ROUTES,
  ...PRIVATE_ROUTES,
} as const;

export const MODAL_ROUTES = {
  successModal: "/successModal",
} as const;
