import type { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import React from "react";
import { tv } from "tailwind-variants";
import type { VariantProps } from "tailwind-variants";

const textVariants = tv({
  variants: {
    variant: {
      primary: "leading-5 text-neutral-black",
      error: "text-sm font-medium text-error-400",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

export type TextProps = PropsWithChildren<
  VariantProps<typeof textVariants> & ComponentPropsWithoutRef<"p">
>;

const Text: React.FC<TextProps> = ({ variant = "primary", ...props }) => {
  const { className, children } = props;
  return (
    <p className={textVariants({ variant, className })} {...props}>
      {children}
    </p>
  );
};

export { Text, textVariants };
