import type { SVGProps } from "~/constants";

export const EyeOffIcon = ({ className, ...props }: SVGProps) => (
  <svg
    className={className}
    {...props}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_365_792)'>
      <path
        d='M6.58663 6.58667C6.39013 6.76977 6.23253 6.99057 6.12321 7.2359C6.0139 7.48123 5.95512 7.74607 5.95038 8.01461C5.94565 8.28315 5.99505 8.54989 6.09563 8.79893C6.19622 9.04796 6.34594 9.27419 6.53586 9.46411C6.72578 9.65402 6.952 9.80374 7.20104 9.90433C7.45007 10.0049 7.71681 10.0543 7.98536 10.0496C8.2539 10.0448 8.51873 9.98606 8.76406 9.87675C9.0094 9.76744 9.2302 9.60983 9.4133 9.41334'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.15332 3.38668C7.43419 3.35168 7.71694 3.33387 7.99999 3.33334C12.6667 3.33334 14.6667 8.00001 14.6667 8.00001C14.3686 8.63809 13.9948 9.23797 13.5533 9.78668'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.40671 4.40668C3.08087 5.30976 2.01996 6.55018 1.33337 8.00001C1.33337 8.00001 3.33337 12.6667 8.00004 12.6667C9.27731 12.6701 10.5272 12.2967 11.5934 11.5933'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.33337 1.33334L14.6667 14.6667'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_365_792'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
