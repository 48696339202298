import type { SVGProps } from "~/constants";

export const ABCKidneyLogoIcon = ({ className, ...props }: SVGProps) => (
  <svg
    className={className}
    viewBox='0 0 219 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M5.70422 28.9592C2.68139 28.9592 0.385566 26.8929 0.385566 24.0232C0.385566 20.0437 2.91097 17.8244 8.8801 17.2505L11.4438 16.9826V14.9547C11.4438 11.664 10.1428 9.48296 7.80872 9.48296C5.16853 9.48296 4.2502 11.7788 4.63283 13.3859C5.092 15.146 4.28846 16.0643 3.14055 16.2939C1.68653 16.6383 0.347303 15.873 0.385566 14.0746C0.462094 11.3961 3.21708 8.41158 8.72705 8.41158C13.5865 8.41158 16.418 11.1283 16.418 15.4138V24.9415C16.418 26.0129 16.8389 26.6634 17.566 26.6634C18.1016 26.6634 18.6373 26.2807 19.173 25.2859L19.6705 25.4772C18.4078 28.5383 16.418 28.9592 15.3849 28.9592C13.7396 28.9592 11.4438 27.7347 11.4438 24.865V24.7502C10.4106 27.5434 8.22962 28.9592 5.70422 28.9592ZM5.35984 22.6457C5.35984 24.5971 6.62255 26.0129 8.38267 26.0129C9.7219 26.0129 10.8315 25.018 11.4438 23.4875V18.0923L9.26274 18.3601C6.66081 18.7428 5.35984 20.1968 5.35984 22.6457Z'
      fill='#FF852F'
    />
    <path
      d='M33.4218 8.41158C38.7022 8.41158 41.5719 12.8119 41.5719 18.1306C41.5719 24.6736 37.5542 28.9592 31.9677 28.9592C29.9398 28.9592 27.9883 28.1556 26.7639 26.8929L23.5115 28.9592H22.9758V5.6566C22.9758 3.74341 22.1722 3.28425 20.68 3.4373V2.90161L27.4144 0.720581H27.9501V12.7736C28.6005 10.3248 30.6668 8.41158 33.4218 8.41158ZM27.9501 22.4926C27.9501 25.2859 29.2893 27.8878 31.7764 27.8878C34.5314 27.8878 36.4063 24.4058 36.4063 19.8142C36.4063 15.0695 34.6845 11.1283 31.2025 11.1283C29.6719 11.1283 28.371 12.3145 27.9501 13.9215V22.4926Z'
      fill='#FF852F'
    />
    <path
      d='M60.542 15.299C59.3176 11.5492 56.9453 9.48296 54.3433 9.48296C51.3588 9.48296 49.1777 12.7736 49.1777 17.0592C49.1777 21.7656 51.9327 25.8216 56.1417 25.8216C58.5141 25.8216 60.3507 24.4441 61.116 21.9569L61.6517 22.0717C61.0395 26.0894 58.208 28.9592 53.8076 28.9592C48.3359 28.9592 44.1652 24.6736 44.1652 18.9341C44.1652 13.0032 48.3742 8.41158 53.6546 8.41158C58.361 8.41158 59.2028 11.09 59.7002 8.41158H60.1977L61.0777 15.299H60.542Z'
      fill='#FF852F'
    />
    <path
      d='M70.1517 22.0125L70.1384 16.235H70.9052L78.2031 8.07786H83.7954L74.8186 18.0727H73.827L70.1517 22.0125ZM65.7888 28.3849V1.30885H70.5747V28.3849H65.7888ZM78.5336 28.3849L71.9232 19.1436L75.1491 15.7723L84.2582 28.3849H78.5336Z'
      fill='#FF852F'
    />
    <path
      d='M86.9849 28.3849V8.07786H91.7708V28.3849H86.9849ZM89.3911 5.19574C88.6331 5.19574 87.9809 4.94455 87.4344 4.44216C86.888 3.93096 86.6147 3.3184 86.6147 2.60448C86.6147 1.88175 86.888 1.26919 87.4344 0.7668C87.9809 0.2556 88.6331 0 89.3911 0C90.1579 0 90.8101 0.2556 91.3478 0.7668C91.8942 1.26919 92.1674 1.88175 92.1674 2.60448C92.1674 3.3184 91.8942 3.93096 91.3478 4.44216C90.8101 4.94455 90.1579 5.19574 89.3911 5.19574Z'
      fill='#FF852F'
    />
    <path
      d='M104.228 28.7418C102.633 28.7418 101.205 28.332 99.9445 27.5123C98.6842 26.6926 97.6882 25.5028 96.9566 23.9427C96.2251 22.3827 95.8593 20.4877 95.8593 18.2578C95.8593 16.0015 96.2295 14.0977 96.9699 12.5465C97.719 10.9864 98.7282 9.80977 99.9974 9.01653C101.267 8.21447 102.681 7.81344 104.241 7.81344C105.431 7.81344 106.409 8.01616 107.176 8.42159C107.943 8.81821 108.551 9.29857 109.001 9.86265C109.45 10.4179 109.798 10.9423 110.045 11.4359H110.243V1.30885H115.043V28.3849H110.336V25.1855H110.045C109.798 25.679 109.441 26.2035 108.974 26.7587C108.507 27.3052 107.89 27.7723 107.123 28.1601C106.357 28.5479 105.391 28.7418 104.228 28.7418ZM105.563 24.8153C106.577 24.8153 107.441 24.542 108.155 23.9956C108.869 23.4403 109.411 22.6691 109.781 21.682C110.151 20.6948 110.336 19.5446 110.336 18.2314C110.336 16.9181 110.151 15.7767 109.781 14.8072C109.419 13.8377 108.882 13.0841 108.168 12.5465C107.463 12.0088 106.595 11.74 105.563 11.74C104.497 11.74 103.607 12.0176 102.893 12.5729C102.179 13.1282 101.641 13.895 101.28 14.8733C100.918 15.8516 100.738 16.971 100.738 18.2314C100.738 19.5005 100.918 20.6331 101.28 21.6291C101.65 22.6162 102.192 23.3963 102.906 23.9692C103.629 24.5332 104.514 24.8153 105.563 24.8153Z'
      fill='#FF852F'
    />
    <path
      d='M124.928 16.4862V28.3849H120.142V8.07786H124.717V11.5285H124.955C125.422 10.3915 126.167 9.48806 127.189 8.81822C128.22 8.14837 129.494 7.81344 131.01 7.81344C132.411 7.81344 133.632 8.11311 134.672 8.71245C135.721 9.31179 136.532 10.1799 137.105 11.3169C137.686 12.4539 137.973 13.8333 137.964 15.455V28.3849H133.178V16.1954C133.178 14.838 132.826 13.776 132.12 13.0092C131.424 12.2424 130.459 11.859 129.225 11.859C128.388 11.859 127.643 12.0441 126.991 12.4142C126.347 12.7756 125.841 13.3 125.47 13.9875C125.109 14.675 124.928 15.5079 124.928 16.4862Z'
      fill='#FF852F'
    />
    <path
      d='M151.819 28.7815C149.783 28.7815 148.025 28.3584 146.544 27.5123C145.072 26.6574 143.94 25.4499 143.147 23.8898C142.353 22.321 141.957 20.4745 141.957 18.3503C141.957 16.2615 142.353 14.4282 143.147 12.8505C143.949 11.264 145.068 10.0301 146.505 9.14873C147.941 8.25854 149.629 7.81344 151.568 7.81344C152.82 7.81344 154.001 8.01616 155.111 8.42159C156.231 8.81821 157.218 9.43518 158.073 10.2725C158.936 11.1098 159.615 12.1763 160.109 13.4719C160.602 14.7587 160.849 16.2923 160.849 18.0727V19.5402H144.204V16.3144H156.261C156.253 15.3977 156.054 14.5824 155.667 13.8685C155.279 13.1458 154.737 12.5773 154.04 12.1631C153.353 11.7488 152.551 11.5417 151.634 11.5417C150.656 11.5417 149.797 11.7797 149.056 12.2556C148.316 12.7227 147.739 13.3397 147.324 14.1065C146.919 14.8645 146.712 15.6974 146.703 16.6052V19.4212C146.703 20.6023 146.919 21.6159 147.351 22.462C147.783 23.2993 148.386 23.9427 149.162 24.3922C149.938 24.8329 150.845 25.0532 151.885 25.0532C152.582 25.0532 153.212 24.9563 153.776 24.7624C154.34 24.5597 154.829 24.2644 155.243 23.8766C155.658 23.4888 155.971 23.0084 156.182 22.4355L160.651 22.9379C160.369 24.119 159.831 25.1502 159.038 26.0316C158.253 26.9041 157.249 27.5828 156.023 28.0676C154.798 28.5435 153.397 28.7815 151.819 28.7815Z'
      fill='#FF852F'
    />
    <path
      d='M167.291 36C166.639 36 166.035 35.9471 165.48 35.8414C164.933 35.7444 164.497 35.6298 164.171 35.4976L165.281 31.7694C165.978 31.9721 166.599 32.069 167.145 32.0602C167.692 32.0514 168.172 31.8795 168.586 31.5446C169.01 31.2185 169.366 30.6721 169.657 29.9053L170.067 28.8079L162.703 8.07786H167.78L172.46 23.4139H172.672L177.365 8.07786H182.455L174.324 30.8439C173.945 31.9192 173.443 32.8403 172.817 33.6071C172.191 34.3827 171.425 34.9732 170.517 35.3786C169.618 35.7929 168.542 36 167.291 36Z'
      fill='#FF852F'
    />
    <path
      d='M195.934 19.4917C198.116 20.3009 199.365 22.6724 198.751 24.983C198.102 27.43 195.61 28.8823 193.186 28.2266C188.337 26.9152 185.548 21.5542 186.955 16.2523C188.363 10.9504 193.434 7.71548 198.283 9.02683C199.899 9.46395 200.858 11.1408 200.425 12.7721C199.992 14.4035 198.33 15.3716 196.714 14.9345C196.221 14.801 195.789 14.5521 195.439 14.2242M195.934 19.4917C195.805 19.444 195.673 19.4017 195.538 19.3651C194.926 19.1996 194.31 19.1684 193.72 19.2537M195.934 19.4917C196.62 19.2894 197.156 18.8046 197.486 18.2009M197.312 15.0961C198.02 16 198.008 17.2485 197.486 18.2009M208.272 19.4916C206.09 20.3008 204.841 22.6723 205.454 24.9829C206.104 27.4299 208.596 28.8821 211.02 28.2265C215.868 26.9151 218.658 21.554 217.25 16.2522C215.843 10.9503 210.772 7.71536 205.923 9.02671C204.307 9.46383 203.348 11.1406 203.781 12.772C204.214 14.4033 205.875 15.3714 207.492 14.9343C207.985 14.8009 208.417 14.552 208.767 14.2241M208.272 19.4916C208.401 19.4438 208.533 19.4015 208.668 19.365C209.28 19.1994 209.896 19.1683 210.486 19.2536M208.272 19.4916C207.585 19.2893 207.05 18.8046 206.719 18.2009M206.894 15.096C206.186 15.9999 206.198 17.2485 206.719 18.2009M206.719 18.2009V18.2009C204.493 18.2009 202.594 19.8121 202.232 22.0084L202.103 22.7879L201.974 22.0084C201.612 19.812 199.713 18.2009 197.486 18.2009V18.2009'
      stroke='#FF8335'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
