import type { SVGProps } from "~/constants";

export const LeftKidneyIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox='0 0 397 713'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M87.7724 823.701C-116.409 768.284 -233.884 541.726 -174.614 317.67C-115.345 93.6142 98.2247 -43.0944 302.406 12.3231C370.467 30.7956 410.857 101.658 392.62 170.598C376.584 231.219 320.557 270.168 260.993 266.126C290.817 304.327 290.844 359.775 268.879 400.021C254.954 425.534 232.409 446.016 203.507 454.568C295.404 488.764 347.985 588.982 322.154 686.629C294.799 790.04 189.863 851.41 87.7724 823.701Z'
      fill='#DBEAFE'
    />
  </svg>
);
