import React from "react";

import { WavingHandIcon } from "~/shared";
import LoginForm from "~/shared/components/LoginForm";
import { Heading } from "~/shared/components/ui/Heading/Heading";

export const Login = () => {
  return (
    <div className='flex w-2/3 flex-col gap-12'>
      <div className='flex items-center justify-center gap-2'>
        <Heading>Welcome back!</Heading>
        <WavingHandIcon className='h-12 w-12' />
      </div>
      <LoginForm />
    </div>
  );
};
