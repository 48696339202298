import type { SVGProps } from "~/constants";

export const RightKidneyIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox='0 0 396 831'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    {...props}
  >
    <path
      d='M309.252 823.701C513.434 768.284 630.908 541.726 571.639 317.67C512.369 93.6142 298.8 -43.0944 94.6179 12.3231C26.5574 30.7956 -13.833 101.658 4.40381 170.598C20.4399 231.219 76.4676 270.168 136.031 266.126C106.208 304.327 106.18 359.775 128.145 400.021C142.07 425.534 164.615 446.016 193.517 454.568C101.62 488.764 49.0395 588.982 74.8702 686.629C102.225 790.04 207.161 851.41 309.252 823.701Z'
      fill='#DBEAFE'
    />
  </svg>
);
