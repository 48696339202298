import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import type { ErrorResponse } from "~/api/axios";
import { login } from "~/api/login";
import { ROUTES } from "~/router";
import { useUserStore } from "~/stores";

export const useLoginMutation = () => {
  const navigate = useNavigate();
  const setToken = useUserStore((state) => state.setToken);

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: login,
    onError: (error: ErrorResponse) => {
      if (error.response?.status === 401) {
        error.message = "Invalid email or password";
      } else {
        error.message = "An unexpected error occurred, please try again later.";
      }
    },
    onSuccess: ({ data }) => {
      setToken(data.token);
      navigate(ROUTES.home);
    },
  });

  return { mutate, isPending, isError, error };
};
