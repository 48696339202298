import forms from "@tailwindcss/forms";
import typography from "@tailwindcss/typography";
import type { Config } from "tailwindcss";
import animate from "tailwindcss-animate";

export default {
  // Change this with the extensions you are going to use.
  content: ["./src/**/*.{html,js,jsx,md,mdx,svelte,ts,tsx,vue,svg}"],
  theme: {
    screens: {
      // These are the default media queries.
      // We're declaring them to make it easier to import and use in react for js checks
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px",
    },
    extend: {
      fontFamily: {
        sans: ["Inter", "sans-serif"],
        tobias: ["TobiasTrial", "sans-serif"],
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
      colors: {
        primary: {
          50: "#eff6ff",
          100: "#dbeafe",
          200: "#bfdafe",
          300: "#93c0fd",
          400: "#60a2fa",
          500: "#3b8bf6",
          600: "#257aeb",
          700: "#1d6dd8",
          800: "#1e5caf",
          900: "#1e4c8a",
          DEFAULT: "#60a2fa",
        },
        gray: {
          50: "#F8F8F8",
          100: "#edeef1",
          200: "#d7dbe0",
          300: "#b3bbc6",
          400: "#8a96a6",
          500: "#6c798b",
          600: "#566173",
        },
        neutral: {
          white: "#FEFEFE",
          background: "#F9FBFF",
          black: "#041C3B",
        },
        error: {
          50: "#fff0f0",
          100: "#ffdddd",
          300: "#ff9595",
          400: "#ff5959",
          700: "#eb0000",
          900: "#900c0c",
          950: "#500000",
        },
        success: {
          50: "#edfff6",
          100: "#d5ffed",
          500: "#00eb81",
          600: "#00c065",
          700: "#009652",
          900: "#07603a",
          950: "#00371f",
        },
        warning: {
          50: "#feffe7",
          100: "#fcffc1",
          300: "#fff841",
          400: "#ffeb0d",
          500: "#ebcb00",
          600: "#d1a200",
          900: "#744a0f",
        },
      },
    },
  },
  plugins: [typography, forms, animate],
} satisfies Config;
