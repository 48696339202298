import React from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "~/shared";

interface BaseFormItemProps<TFormValues extends FieldValues> {
  name: Path<TFormValues>;
  label: string;
  type: React.HTMLInputTypeAttribute;
  placeholder?: string;
  control: Control<TFormValues>;
  hasError: boolean;
}

const BaseInputFormItem = <TFormValues extends FieldValues>({
  name,
  label,
  type,
  placeholder,
  control,
  hasError,
}: BaseFormItemProps<TFormValues>) => {
  return (
    <FormField
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormItem>
          <FormLabel className='font-medium'>{label}</FormLabel>
          <FormControl>
            <Input
              type={type}
              placeholder={placeholder}
              {...field}
              hasError={!!error || hasError}
            />
          </FormControl>
          {error && <FormMessage>{error.message}</FormMessage>}
        </FormItem>
      )}
    />
  );
};

export default BaseInputFormItem;
